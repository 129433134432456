const prefix = 'nativechat';

export const ElementIds = {
  launcher: `${prefix}-launcher`,
  launcherIconClose: `${prefix}-launcher-icon-close`,
  launcherIconOpen: `${prefix}-launcher-icon-open`,
  launcherModal: `${prefix}-launcher-modal`,
};

export const RtlLocales = ['ar'];
