import { ElementIds } from './Constants';
import { DeviceHelper } from './DeviceHelper';
import { Disposable } from './Disposable';
import { LauncherSettings } from './Settings';

export class ChatLauncher implements Disposable {
  private settings?: LauncherSettings;
  private id: string;

  private element?: HTMLDivElement;
  private openElement?: HTMLDivElement;
  private closeElement?: HTMLDivElement;

  private proactiveOpenerTimeout?: NodeJS.Timer;

  private openHandler?: () => any;
  private closeHandler?: () => any;

  constructor(id: string, openHandler: () => any, closeHandler: () => any, settings: LauncherSettings = {}) {
    this.settings = settings;
    this.id = id;

    this.openHandler = openHandler;
    this.closeHandler = closeHandler;

    this.element = document.createElement('div');
    this.element.id = `${ElementIds.launcher}-${this.id}`;
    this.element.innerHTML = `<div class="${ElementIds.launcherIconOpen}" aria-label="open chat" role="button"></div><div class="${ElementIds.launcherIconClose}" aria-label="close chat" role="button"></div>`;

    document.body.appendChild(this.element);

    this.openElement = document.querySelector<HTMLDivElement>(`#${ElementIds.launcher}-${this.id} .${ElementIds.launcherIconOpen}`) as HTMLDivElement;
    this.closeElement = document.querySelector<HTMLDivElement>(`#${ElementIds.launcher}-${this.id} .${ElementIds.launcherIconClose}`) as HTMLDivElement;

    this.openElement.onclick = this.open.bind(this);
    this.openElement.onkeydown = this.keydown(this.openElement);
    this.closeElement.onclick = this.close.bind(this);
    this.closeElement.onkeydown = this.keydown(this.closeElement);

    this.openElement.tabIndex = 0;

    if (this.settings.openIconUrl) {
      this.openElement.style.backgroundImage = `url(${this.settings.openIconUrl})`;
    }

    if (this.settings.closeIconUrl) {
      this.closeElement.style.backgroundImage = `url(${this.settings.closeIconUrl})`;
    }

    this.openProactivelyIfNeeded();
  }

  public dispose() {
    if (this.openElement) {
      this.openElement.onclick = null;
      this.openElement.onkeydown = null;
    }

    if (this.closeElement) {
      this.closeElement.onclick = null;
      this.closeElement.onkeydown = null;
    }

    if (this.element && this.element.parentNode) {
      this.element.parentNode.removeChild(this.element);
    }

    this.element = undefined;
    this.openElement = undefined;
    this.closeElement = undefined;
    this.openHandler = undefined;
    this.closeHandler = undefined;
  }

  private open() {
    if (this.proactiveOpenerTimeout) {
      clearTimeout(this.proactiveOpenerTimeout);
      this.proactiveOpenerTimeout = undefined;
    }

    if (!DeviceHelper.isMobile()) {

      if (this.openElement) {
        this.openElement.tabIndex = -1;
        this.openElement.style.display = 'none';
      }

      if (this.closeElement) {
        this.closeElement.tabIndex = 0;
        this.closeElement.style.display = 'block';
      }

    }

    if (this.openHandler) {
      this.openHandler();
    }
  }

  private close() {
    if (this.openElement) {
      this.openElement.tabIndex = 0;
      this.openElement.style.display = 'block';
    }

    if (this.closeElement) {
      this.closeElement.tabIndex = -1;
      this.closeElement.style.display = 'none';
    }

    if (this.closeHandler) {
      this.closeHandler();
    }
  }

  private keydown = (element: HTMLDivElement) => (event: KeyboardEvent) => {
    if (event.code === 'Enter' || event.code === 'Space') {
      element.click();
    }
  }

  private openProactivelyIfNeeded() {
    if (typeof this.settings?.popupDelayInMs !== 'number') {
      return;
    }

    this.proactiveOpenerTimeout = setTimeout(() => {
      this.open();
    }, this.settings.popupDelayInMs);
  }
}
