interface IdComposite {
  id: string;
}

export interface SDKSettings extends IdComposite {
  origin: string;
  display: InlineDisplay | ModalDisplay;
  chat: ChatSettings;
}

export interface LauncherSettings {
  openIconUrl?: string;
  closeIconUrl?: string;
  popupDelayInMs?: number;
}

export interface BotSettings extends IdComposite {
  avatarUrl?: string;
  name?: string;
}

export interface ChannelSettings extends IdComposite {
  token: string;
}

export interface ChatSettings {
  bot: BotSettings;
  channel: ChannelSettings;
  user: any;
  session?: any;
  googleApiKey?: string;
  css?: string[];
  showDebugConsole: boolean;
  showFilePicker?: boolean;
  showLocationPicker?: boolean;
  placeholder?: string;
  submitLocationText?: string;
  chatServerInstance?: string;
  colorPrimaryBackground?: string;
  colorPrimaryText?: string;
  locale?: string;
  webchatVersion: string;
}

export enum Display {
  Modal = 'modal',
  Inline = 'inline',
}

export interface InlineDisplay {
  mode: Display.Inline;
  containerId: string;
}

export interface ModalDisplay {
  mode: Display.Modal;
  launcher?: LauncherSettings;
}
